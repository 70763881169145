<template>
  <div class='lender-away-container'
    @keydown.down="moveFocus('next', $event)"
    @keydown.space="moveFocus('next', $event)"
    @keydown.up="moveFocus('prev', $event)"
    @keydown.shift.tab='exitIM'
    tabindex='-1'
    :aria-label="'Response from ' + instantMessage.sender.full_name"
    role='region'
    >
    <div class='lender-away'>
      <div class='row lender-away__message'>
        <span class='col-12 brand brand--sm'>{{ instantMessage.content }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'away-message',
  props: {
    instantMessage: {
      required: true,
      type: Object,
    },
    lender: {
      required: true,
      type: Object,
    },
    exitIM:{
      type: Function,
    }
  },
};
</script>

<style lang='scss'>
  @import "../assets/stylesheets/base";

.lender-away-container {
  margin-top: 0.7rem;

  & .lender-away {
      padding: 0 0.25rem;
      margin: 0.5rem 0;
      border-top: 0.1875rem $light-grey;
      border-bottom: 0.1875rem $light-grey;
      &__header {
        padding: 0.5rem 0;
        align-items: center;
      }
      &__message {
        padding-bottom: 0.75rem;
      }
    }
}

</style>
