<template>
  <section
    role='log'
    aria-label='chat history'
    id='message-log'
    class='messages-history'
    v-auto-scroll='scrollMessages'
    tabindex="0"
    @keydown.down.self="focusOnLastMessage"
    @keydown.enter.self="focusOnLastMessage"
  >
    <error-overlay v-if='error' />
    <div class="messages-history__history-area">
      <div v-if="canChat" class="messages-history__begin-text">
        <span>This is the beginning of your conversation with your loan team.</span>
      </div>
      <div id='recent-messages' class="messages-history__recent-messages" tabindex="-1">
        <instant-message
          v-for='message in instantMessages'
          :key='message.id'
          :instant-message='message'
          :current-user-id='currentUserId'
          :participants='participants'
        />
      </div>
    </div>
  </section>
</template>

<script>
import LoadingOverlay from 'shared/components/LoadingOverlay';
import ErrorOverlay from 'shared/components/ErrorOverlay';
import InstantMessage from 'messaging/components/InstantMessage';
import MessageTimestamp from 'messaging/components/MessageTimestamp';
import { last } from 'lodash';

export default {
  name: 'messages-history',
  props: {
    participants: {
      required: false,
      type: Array,
      default() {
        return [];
      },
    },
    currentUserId: {
      required: true,
      type: String,
    },
    instantMessages: {
      required: true,
      type: Array,
    },
    eventHub: {
      type: Object,
      required: true,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    error: {
      default: null,
      type: String,
    },
    canChat: {
      type: Boolean,
    },
  },
  computed: {
    lastMessage() {
      return last(this.instantMessages);
    },
    instantMessagesArray() {
      return this.$el.querySelectorAll('.instant-message');
    },
  },
  components: {
    shared: {
      LoadingOverlay,
      ErrorOverlay,
    },
    apps: {
      'instant-messaging': {
        InstantMessage,
        MessageTimestamp,
      },
    },
  },
  methods: {
    scrollMessages() {
      const container = $(this.$el).find('.messages-history__history-area').get(0);
      container.scrollTop = (container.scrollHeight + container.offsetHeight);
    },
    focusOnInstantMessage(i) {
      const instantMessagesArray = this.instantMessagesArray;
      instantMessagesArray[i].setAttribute('tabindex', 0);
      instantMessagesArray[i].focus();
    },
    focusOnLastMessage() {
      this.focusOnInstantMessage(this.instantMessagesArray.length - 1);
      this.scrollMessages();
    },
  },
  directives: {
    autoScroll: {
      componentUpdated: (el, binding) => {
        binding.value();
      },
    },
  },
  data() {
    return {
      currentlyFocusedEl: null,
    };
  },
  created() {
    this.eventHub.$on('scroll-log', this.scrollMessages);
  },
  beforeUpdate() {
    this.currentlyFocusedEl = document.activeElement;
  },
  watch: {
    instantMessages() {
      const previouslyFocussedEl = this.currentlyFocusedEl;

      // body becomes focussed if user receives new message while in textarea
      if (this.currentlyFocusedEl === document.querySelector('body')) {
        return;
      }

      if (this.$el.querySelector('#recent-messages').lastElementChild) {
        setTimeout(() => {
          this.$el.querySelector('#recent-messages').lastElementChild.focus();
        }, 50);
      }

      setTimeout(() => {
        if (previouslyFocussedEl) {
          previouslyFocussedEl.focus();
        }
      }, 4000);
    },
  },
};
</script>

<style lang='scss'>
  @import "../assets/stylesheets/base";

  .messages-history {
    height: inherit;
    width: inherit;
    position: relative;

    &__history-area {
      position: absolute;
      bottom: 0;
      padding-right: 1.6rem;
      overflow-y: scroll;
      width: inherit;
      -webkit-overflow-scrolling: touch;
      max-height: 100%;
    }

    .vue-loading {
      display: block; /* .loading is normally hidden */
      padding: 0.5em;
      margin: 0.5em;
      background-color: #F8F8F8;
      border: 1px lightgrey solid;
    }

    &__begin-text {
      @include f-xs;
      line-height: 1.14rem;
      text-align: center;
      padding-top: 1rem;

      & > span {
        width: 14.4rem;
        display: inline-block;
      }
    }

    &__recent-messages {
      padding-bottom: 1rem;
    }
  }
</style>
