<template>
  <transition name="conv-slide" mode="in-out">
    <active-conversation
      v-if="showActiveConversation"
      :activeLoanApp="activeLoanApp"
      :current-participant="currentParticipant"
      :messages="messages"
    />
  </transition>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { find } from 'lodash';
import ActiveConversation from 'messaging/components/ActiveConversation';

export default {
  name: 'active-conversation-container',
  computed: {
    ...mapState('users', ['currentUserId']),
    ...mapState('instantMessages', {
      showActiveConversation: 'showActiveConversation',
      activeLoanApp: 'activeLoanApp',
      participants: 'participants',
    }),
    ...mapGetters('instantMessages', {
      messages: 'messagesByCreatedAt',
    }),
    currentParticipant() {
      const currentParticipant =
        find(this.participants, participant => participant.user.id === this.currentUserId);
      return currentParticipant;
    },
  },
  components: {
    apps: {
      'instant-messaging': {
        ActiveConversation,
      },
    },
  },
  beforeMount() {
  },
};
</script>

<style lang="scss">
  @import "../assets/stylesheets/base";

  .conv-slide-enter-active {
    transition: 0.7s;
  }

  .conv-slide-enter {
    transform: translate(100%, 0);
  }

</style>

