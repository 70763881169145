<template>
  <span role="region" class='instant-message-timestamp disclaimer-text'>
    <timeago :since='instantMessage.created_at' :auto-update="60" :format="format"></timeago>
  </span>
</template>

<script>
import timeAgoInWords from 'shared/filters/timeAgoInWords';

export default {
  name: 'message-timestamp',
  props: {
    instantMessage: {
      type: Object,
      required: true,
    },
  },
  computed: {
  },
  components: {
  },
  methods: {
    format(val) {
      return timeAgoInWords(val);
    },
  },
  directives: {
  },
  created() {
  },
};

</script>

<style lang='scss'>
  @import "../assets/stylesheets/base";

  .instant-message-timestamp {
    text-align: right;
  }
</style>
