<template>
  <transition name="conv-list-slide" mode="out-in">
    <conversations-list
      v-if="showList"
      :loanApps="loanApps"
      :action="showActiveConversationAction"
    />
  </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ConversationsList from 'messaging/components/ConversationsList';

export default {
  name: 'conversations-list-container',
  computed: {
    ...mapState('users', ['currentUserId']),
    ...mapState('instantMessages', {
      showActiveConversation: 'showActiveConversation',
      loanApps: 'loanApps',
    }),
    showList() {
      return !this.showActiveConversation && (this.loanApps && this.loanApps.length);
    },
  },
  methods: {
    ...mapActions('instantMessages', {
      showActiveConversationAction: 'showActiveConversation',
    }),
  },
  components: {
    apps: {
      'instant-messaging': {
        ConversationsList,
      },
    },
  },
  beforeMount() {
  },
};
</script>

<style lang="scss">
  @import "../assets/stylesheets/base";

  .conv-list-slide-enter-active {
    transition: 0.7s;
  }

  .conv-list-slide-enter {
    transform: translate(-100%, 0);
  }

</style>

