<template>
  <div id='instant-messaging-container' class="instant-messaging-container js-instant-messaging-container">
    <active-conversation-container />
    <conversations-list-container />
    <notice-pane-container />
  </div>
</template>

<script>
import ConversationsListContainer from 'messaging/containers/ConversationsListContainer';
import ActiveConversationContainer from 'messaging/containers/ActiveConversationContainer';
import NoticePaneContainer from 'messaging/containers/NoticePaneContainer';

export default {
  name: 'instant-messaging-app',
  data() {
    return {
    };
  },
  components: {
    apps: {
      'instant-messaging': {
        ConversationsListContainer,
        ActiveConversationContainer,
        NoticePaneContainer,
      },
    },
  },
  methods: {
  },
  computed: {
  },
  beforeMount() {
  },
};
</script>

<style lang='scss'>
@import "./assets/stylesheets/base";

.instant-messaging-container {
  width: 100%;
  height: 100%;
  position: absolute;
  border-left: 2px solid $gainsboro;
}

</style>
