<template>
  <section class='send-message pl-3'
           v-scroll-log='eventHub'
           >
    <form id='instant-message-form'
          class='send-instant-message-form'
          @submit.prevent='submit'
          >

      <participant-status v-if="showStatus" :participant="borrowerParticipant" />

      <div v-if="currentParticipant && !showStatus" class="brand send-instant-message-form__send-label mb-2">
        Leave a message for your loan team:
      </div>

      <label
        for='new-instant-message'
        class='sr-only'
        v-if='showLabel'
        >
        New message
      </label>
      <textarea
        class='send-instant-message-form__textarea js-instant-message-input js-autosize'
        name='instant_message[content]'
        id='new-instant-message'
        title='New message'
        aria-label='New message'
        :rows='rows'
        :disabled='updating'
        ref='messageInput'
        v-model='newInstantMessage.content'
        @input='onTyping(activeLoanApp)'
        @keydown.enter="submit"
        >
      </textarea>
      <span class="send-instant-message-form__submit-container">
        <button
          type='submit'
          value='Send'
          :disabled='updating || !newInstantMessage.content.trim()'
          aria-label='Send message'
          class="btn btn--circle btn--disguised btn--xs send-instant-message-form__submit-btn"
          >
          <i v-html="sendButtonIcon"></i>
       </button>
      </span>

    </form>
  </section>
</template>

<script>
import userAgent from 'shared/util/userAgent';
import ParticipantStatus from 'messaging/components/ParticipantStatus';

export default {
  name: 'send-message',
  props: {
    action: {
      type: Function,
      required: true,
    },
    onTyping: {
      type: Function,
      required: false,
    },
    eventHub: {
      type: Object,
      required: true,
    },
    activeLoanApp: {
      type: Object,
      required: true,
    },
    borrowerParticipant: {
      type: Object,
    },
    showStatus: {
      type: Boolean,
    },
    currentParticipant: {
      type: Object,
    },
    sendButtonIcon: {
      type: String,
    }
  },
  data() {
    return {
      rows: 1,
      updating: false,
      error: null,
      newInstantMessage: {
        content: '',
      },
    };
  },
  computed: {
    showLabel() {
      return userAgent.onlyFF();
    },
  },
  methods: {
    enableAndFocusInput() {
      this.updating = false;
      setTimeout(() => {
        this.$refs.messageInput.focus();
      }, 500);
    },
    resetTextAreaHeight() {
      const textAreaElement = document.getElementsByName('instant_message[content]');
      textAreaElement[0].style.cssText = 'height: initial !important;';
    },
    submit(e) {
      const textContent = this.newInstantMessage.content;
      if (e.shiftKey || !textContent.trim()) {
        return false;
      }
      e.preventDefault();
      this.updating = true;
      this.action(this.newInstantMessage)
        .then(() => {
          this.rows = 1;
          this.newInstantMessage = { content: '' };
          this.enableAndFocusInput()
          this.resetTextAreaHeight();
        })
        .catch((error) => {
          this.error = error.message;
          this.enableAndFocusInput()
        });
      return true;
    },
  },
  components: {
    ParticipantStatus,
  },
  directives: {
    scrollLog: {
      componentUpdated: (el, binding) => {
        binding.value.$emit('scroll-log');
      },
    },
  },
};
</script>

<style lang='scss'>
@import "../assets/stylesheets/base";

.send-message {
  @include make-container();

  order: 3;
  width: 100%;
  border-top: 2px solid $gainsboro;
}

.send-instant-message-form {

  @include make-row();
  margin: 0;

  &__textarea {
    @include make-col-ready();
    @include make-col(10);
    height: initial;
    padding-left: 1rem;
    overflow: hidden;
    max-height: 5.25rem;
    border-radius: 0.25rem;
  }

  &__submit-container {
    @include make-col-ready();
    @include make-col(2);

    text-align: center;
    align-self: flex-end;
  }

  &__submit {
    &-i {
      color: $light-navy;
      font-size: 1.4rem;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        position: relative;
        left: -0.6rem;
      }
    }
    &-btn {
      width: 2.5rem;
      height: 2.5rem;
      &:disabled {
        background: transparent;
        border: transparent;
        .send-instant-message-form__submit-i {
          color: $battleship-grey;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .send-instant-message-form {
    &__textarea {
      max-height: 20rem;
      overflow-y: scroll;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      &__textarea {
        max-height: 15rem;
        overflow-y: scroll;
        -ms-overflow-style: none;
      }
    }
  }
}

@include media-breakpoint-only(md) {
  .send-instant-message-form { padding-bottom: 2rem; }
}

</style>
