/* eslint-disable */
// disable eslint for now
/* global RoostifyVariables */
import { reduce, isArray, keyBy, orderBy, filter, isEmpty, map, mapValues } from 'lodash';
import * as instantMessagesApi from 'shared/api/instantMessages';
import { Roles } from 'shared/constants/rolesConstants';

function updateUnreadCount(state, token, value) {
  if (state.loanApps) {
    state.loanApps.forEach(loanApp => {
      if(loanApp.token === token) {
        const count = loanApp.unread_count || 0;
        loanApp.unread_count = value ? (count + value) : 0;
      }
    });
  }
  return token;
}

function updateUnreadCountBorrower(state, value){
  if (state.activeLoanApp && RoostifyVariables.is_borrower){
    const count = state.activeLoanApp.unread_count || 0;
    const updatedCount = count + value;
    state.activeLoanApp.unread_count = updatedCount;
    $('.js-unread-count').removeClass('hide');
    $('.js-unread-count').text(updatedCount);
  }
}

function hasLender(participants) {
  return !isEmpty(filter(participants, p => p.role.global === Roles.LENDER));
}

//sets focus on given element, with class based selector and relevant delay ( default half second delay for component transtion to complete )
function moveFocusTo(config) {
  const {className, delay=500} = config;

  setTimeout( () => {
    const elem = document.getElementsByClassName(className);
    elem && elem[0].focus();
  }, delay );
}

export const mutations = {
  setLoading(state) {
    state.loading = true;
  },
  setError(state, { error }) {
    state.error = error;
    state.loading = false;
  },
  setMessagesAndParticipants(state, [messages, participants]) {
    state.loading = false;
    state.error = null;
    state.messages = keyBy(messages, 'id');
    state.participants = participants;
    state.activeLoanApp.lender = hasLender(participants);
  },
  showActiveConversation(state) {
    state.showActiveConversation = true;
  },
  hideActiveConversation(state) {
    state.showActiveConversation = false;
  },
  moveFocusToConversationListItem(state) {
    const listItemClass = `js-toggle-conversations-list__item--${state.activeLoanApp.token}`;
    moveFocusTo({className: listItemClass});
  },
  moveFocusToBackNav(state) {
    moveFocusTo({className: 'js-toggle-conversations-list'});
  },
  updateActiveLoanApp(state, loanApp) {
    state.activeLoanApp = {...RoostifyVariables, ...loanApp};
  },
  resetActiveLoanApp(state) {
    state.activeLoanApp = {...RoostifyVariables};
  },
  resetParticipants(state) {
    state.participants = [];
  },
  resetMessages(state) {
    state.messages = [];
  },
  incrementUnreadCount(state, token) {
    updateUnreadCount(state, token, 1);
  },
  resetUnreadCount(state, token) {
    updateUnreadCount(state, token, 0);
  },
  incrementUnreadCountBorrower(state){
    updateUnreadCountBorrower(state, 1)
  }
};

export const actions = {
  loadMessagesAndParticipants({ commit, state }) {
    let message_url = state.activeLoanApp.instantMessagesBaseUrl;
    let part_url = state.activeLoanApp.teamMembershipsBaseUrl;

    commit('setLoading');
    let messages = instantMessagesApi.fetchMessages(message_url);
    let participants = instantMessagesApi.fetchParticipants(part_url);

    Promise.all([messages, participants])
    .then(values => commit('setMessagesAndParticipants', values))
    .catch(error => commit('setError', { error: error.message }));
  },
  create({ dispatch, commit, state }, instantMessageData) {
    return instantMessagesApi.create(instantMessageData, state.activeLoanApp.instantMessagesBaseUrl)
      .then((data) => {
        if (data.errors !== undefined) {
          if (!isArray(data.errors)) data.errors = [data.errors];
          var message = reduce(data.errors, function(result, error, index) {
            var this_message = error.detail ? error.detail : error;
            if (index === 0) return this_message;
            return [result, this_message].join(", ");
          }, "");

          $.R.Notifications.showWarningNotification(message);
          return data;
        }

        $.R.Notifications.showCustomSuccessNotification('Message sent.');
        return data;
      })
      .catch((error) => {
        var error_text = "Something went wrong, please refresh the page. If the problem persists, please call or email your lender.";
        $.R.Notifications.showWarningNotification(error_text);
      });
  },
  showActiveConversation({dispatch, commit}, loanApp) {
    commit('resetParticipants');
    commit('resetMessages');
    commit('resetUnreadCount', loanApp.token);
    commit('updateActiveLoanApp', loanApp);

    commit('showActiveConversation');
    commit('moveFocusToBackNav');
  },
  hideActiveConversation({dispatch, commit}) {
    commit('hideActiveConversation');
    commit('moveFocusToConversationListItem');
    commit('resetActiveLoanApp');
  },
  incrementUnreadCount({dispatch, commit, state}, token) {
    commit('incrementUnreadCount', token);
  },
  incrementUnreadCountBorrower({dispatch, commit, state}){
    commit('incrementUnreadCountBorrower');
  },
};

export default {
  namespaced: true,
  state: {
    messages: [],
    participants: [],
    loading: false,
    error: null,
    showActiveConversation: !RoostifyVariables.messageable_loan_applications,
    loanApps: RoostifyVariables.messageable_loan_applications,
    activeLoanApp: RoostifyVariables,
  },
  getters: {
    messagesByCreatedAt(state) {
      return orderBy(state.messages, 'created_at', 'asc');
    },
    participantsByRole(state) {
      return orderBy(state.participants, 'role', 'desc');
    },
  },
  mutations,
  actions,
};
