<template>
  <away-message
    v-if='isAwayMessage'
    :lender = 'lender'
    :instant-message = 'instantMessage'
    :exit-IM = 'exitIM'
    />

  <div class='instant-message'
           v-bind:class='currentUser'
           @keydown.down="moveFocus('next', $event)"
           @keydown.space="moveFocus('next', $event)"
           @keydown.up="moveFocus('prev', $event)"
           @keydown.shift.tab='exitIM'
           tabindex='-1'
           :aria-label="'Response from ' + instantMessage.sender.full_name"
           role='region'
           v-else
           >

    <figure class='left-pane'>
      <div role="img"
           class="left-pane__sender-image rounded-circle"
           :title='instantMessage.sender.full_name'
           :style="`background-image: url( ${instantMessage.sender.icon_url}  ); background-color: ${this.senderBgColor}`"
      >
      </div>
    </figure>

    <div class='right-pane'>

      <span class="right-pane__lo-name"> {{ instantMessage.sender.full_name}} </span>

      <div role="log" class="right-pane__content .pt-1 .time-stamp time-stamp--secondary">
        {{ instantMessage.content }}
      </div>

      <message-timestamp role="region" class="right-pane__timestamp" :instant-message='instantMessage' />

    </div>

  </div>
</template>

<script>
import userAgent from 'shared/util/userAgent';
import MessageTimestamp from 'messaging/components/MessageTimestamp';
import AwayMessage from 'messaging/components/AwayMessage';
import { Roles } from 'shared/constants/rolesConstants';
import { find } from 'lodash';

export default {
  name: 'instant-message',
  props: {
    currentUserId: {
      required: true,
      type: String,
    },
    participants: {
      required: false,
      type: Array,
      default: [],
    },
    instantMessage: {
      required: true,
      type: Object,
    },
  },
  computed: {
    role() {
      return userAgent.onlyIE() ? 'alert' : false;
    },
    currentUser() {
      return {
        'current-user': this.instantMessage.sender.id === this.currentUserId,
      };
    },
    senderBgColor() {
      let found = this.participants.find(p => p.user.id === this.instantMessage.sender.id);
      return found ? found.bg_color : "";
    },
    isAwayMessage() {
      return this.instantMessage.is_away_message
    },
    lender() {
      return find(this.participants, p => p.isLender);
    },
  },
  components: {
    apps: {
      'instant-messaging': {
        MessageTimestamp,
        AwayMessage,
      },
    },
  },
  methods: {
    moveFocus(direction, event) {
      event.preventDefault();
      const currentEl = document.activeElement;
      const previousEL = currentEl.previousElementSibling;
      const nextEL = currentEl.nextElementSibling;
      if (direction === 'prev' && previousEL) {
        currentEl.previousElementSibling.focus();
        currentEl.previousElementSibling.setAttribute('tabindex', 0);
        currentEl.setAttribute('tabindex', '-1');
      } else if (direction === 'next' && nextEL) {
        currentEl.nextElementSibling.focus();
        currentEl.nextElementSibling.setAttribute('tabindex', 0);
        currentEl.setAttribute('tabindex', '-1');
      } else {
        // tell screenreader "there are no more messages"
      }
    },
    exitIM() {
      document.activeElement.setAttribute('tabindex', '-1');
    },
  },
  directives: {
  },
};
</script>

<style lang='scss'>
  @import "../assets/stylesheets/base";

  .instant-message {
    @include make-row();
    margin-top: 0.75rem;

    &:focus {
      outline: 1px dashed #000;
    }

    & .left-pane {
      @include make-col-ready();
      @include make-col(1.75);

      min-width: 2.2rem;

      &__sender-image {
        width: 2.2rem;
        height: 2.2rem;
        background-size: cover;
        background-color: $battleship-grey;
        border: solid 1px $gainsboro;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    & .right-pane {
      @include make-col-ready();
      @include make-col(10.25);

      &__lo-name {
        @include h6();
      }

      &__timestamp{
        padding: 0.125rem;
      }

      &__content {
        line-height: 1.36rem;
      }
    }

  }

  .instant-message.current-user {

    & .left-pane {
      display: none;
    }

    & .right-pane {
      @include make-col-ready();
      @include make-col(12);

      &__lo-name {
        display: none;
      }

      &__timestamp{
        float: right;
      }

      &__content {
        clear: both;
        padding: .75rem;
        background-color: #f3f1ee;
        border-radius: 0.25rem;
      }
    }

  }

  @include media-breakpoint-up(xl) {
    .instant-message {
      & .right-pane {
        &__timestamp{
          display: block;
          text-align: left;
        }
      }
    }
  }

.lender-away {
    padding: 0 0.25rem;
    margin: 0.5rem 0;
    border-top: 0.1875rem dashed $light-grey;
    border-bottom: 0.1875rem dashed $light-grey;
    &__header {
      padding: 0.5rem 0;
      align-items: center;
    }
    &__message {
      padding-bottom: 0.75rem;
    }
  }

</style>
