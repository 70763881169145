<template>
  <div class="person-card-wrapper">
    <div class='person-card'>
      <div class="person-card__userpic" v-if="enableLoPhoto">
        <div
          role="img"
          class="person-card__userpic__img rounded-circle"
          :title="user.full_name || 'Invited'"
          :style="`background-image: url( ${user.icon_url}  ); background-color: ${person.bg_color}`"
        >
        </div>
      </div>

      <div class='person-card__details'>
        <div  class='person-card__name-icon'>
            <div class='person-card__details--row full-name-row brand brand--lg mb-1'>
              <h2 class='person-card__full-name'>
                {{ user.full_name || 'Invited'}}
              </h2>
            </div>
            <div class="person-card__delete" v-if='showDeleteBtn'>
              <button class="btn btn--circle btn--disguised btn--xs person-card__delete-btn"
                      :data-target="`#vanity_box_delete_user_${user.id}`"
                      data-toggle="modal"
                      :title="`remove ${person.role.team}`"
                      aria-haspopup="true"
                      :aria-label="`Remove ${person.role.team} ${user.full_name || 'Invited'}`">
                <i v-html="deleteButtonIcon"></i>
              </button>
            </div>
        </div>

        <div class='person-card__details--row'>
          <div class='person-card__role brand brand--sm'>
            {{ person.role.team }}
          </div>
          <div v-if='enableLicenseNumber && user.license_number' class='person-card__license-number'>
            {{ licenseFragment }}
          </div>
        </div>
      </div>
    </div>

    <div :class="contactDetailsVisibility">
        <div
          class='person-card__details--row'
          v-if="user.email"
          >
          <span class='person-card__contact'>
            <i class="fal fa-envelope person-card__contact-icon"></i>

            <a :href="'mailto:' + user.email" class="person-card__contact-txt time-stamp link f-sm">
              {{ user.email }}
            </a>
          </span>
        </div>

        <div
          class='person-card__details--row'
          v-if="user.phone"
          >
            <span class='person-card__contact'>
              <i class="fal fa-phone person-card__contact-icon"></i>

              <span class="person-card__contact-txt-span">
                Office {{ user.phone | phoneFormat }}
              </span>

              <a :href="'tel:' + user.phone" class="js-pane-focusable-elem person-card__contact-txt-a time-stamp">
                Office {{ user.phone | phoneFormat }}
              </a>
            </span>
        </div>

        <div
          class='person-card__details--row'
          v-if="user.mobile_phone"
          >
            <span class='person-card__contact'>
              <i class="fal fa-phone person-card__contact-icon"></i>

              <span class="person-card__contact-txt-span">
                Mobile {{ user.mobile_phone | phoneFormat }}
              </span>

              <a :href="'tel:' + user.mobile_phone" class="person-card__contact-txt-a">
                Mobile {{ user.mobile_phone | phoneFormat }}
              </a>
            </span>
        </div>
      </div>
    </div>
</template>
<script>
/* global RoostifyVariables */
  export default {
    name: 'person-card',
    filters: {
      phoneFormat(number) {
        function convertToNumberFormat() {
          const cleanNumber = number.replace(/\D/g, '');
          const separator = '-';
          const extension = 'x';

          const tenDigitPhone = `(${cleanNumber.slice(0, 3)}) ${cleanNumber.slice(3, 6)}-${cleanNumber.slice(6, 10)}`;

          const extensionPhone = cleanNumber.slice(0, 3) + separator + cleanNumber.slice(3, 6) +
            separator + cleanNumber.slice(6, 10) + extension + cleanNumber.slice(10);

          if (cleanNumber.length > 10) {
            return extensionPhone;
          }
          return tenDigitPhone;
        }

        let formattedNumber = '';
        if (number) {
          formattedNumber = convertToNumberFormat();
        }

        return formattedNumber;
      },
    },
    props: {
      person: {
        required: true,
        type: Object,
      },
      showContactDetails: {
        required: false,
        type: Boolean,
        default: true,
      },
      enableLicenseNumber: {
        required: false,
        type: Boolean,
        default: true,
      },
      enableLoPhoto: {
        type: Boolean,
        default: true,
      },
      globalOrAccountAdmin: {
        type: Boolean,
      },
      globalOrAccountAdminOrLender: {
        type: Boolean,
      },
      deleteButtonIcon: {
        type: String,
      },
    },
    computed: {
      user() {
        return this.person.user;
      },
      licenseFragment() {
        let fragment = '';
        if (this.user.license_number) {
          fragment = `NMLS #${this.user.license_number}`;
        }
        return fragment;
      },
      contactDetailsVisibility() {
        return this.showContactDetails ? 'show-contact-details' : 'hide-contact-details';
      },
      showDeleteBtn() {
        return RoostifyVariables.loan_app_allow_write &&
               (!RoostifyVariables.is_borrower || (!this.person.isLender || this.globalOrAccountAdminOrLender )) &&
               !this.person.canNotDelete;
      },
    },
  };
</script>

<style lang='scss'>
@import "../assets/stylesheets/base";

@mixin placeholder-styles {
  background-color: #777;
  border: none;
}

.hide-contact-details {
  display: none;
}

.person-card-wrapper {
  margin-bottom: 1rem;
}

.person-card {
  @include make-row();

  align-content: space-around;
  color: $readable-gray;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 0.75rem;
  justify-content: flex-start;
  margin-bottom: 1rem;
  margin-left: 0;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }

  &:first-child { padding-top: 0; }

  &__full-name {
    text-transform: capitalize;
    padding-top: 0.3rem;
  }

  &__contact {
    display: inline-flex;
    word-break: break-all;

    &-icon {
      font-size: 1.125rem;
      color: $battleship-grey;
      width: 1.5rem;
      opacity: 0.6;
      padding-right: 1rem;
    }

    &-txt, &-txt-a {
      line-height: 1.14rem;
      color: $light-navy;
    }

    &-txt-span{
      @include f-sm();
      display: none;
    }
  }

  &__userpic {
    min-width: 5rem;
    padding-left: 0;

    &__img {
      width: 4.3rem;
      height: 4.3rem;
      background-color: $battleship-grey;
      border: solid 1px $gainsboro;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &__role {
    line-height: 1.43rem;
    color: $brownish-grey;
    margin-bottom: 0.25rem;
  }

  &__license-number {
    @include f-xs();
  }

  &__name-icon {
    display: flex;
    justify-content: space-between;
  }

  &__details {
    @include make-col-ready();
    @include make-col(9);

    flex-flow: column;
    flex-wrap: nowrap;
    justify-content: space-between;

    &--column {
      align-content: center;
      align-items: center;
      flex-flow: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      order: 3;
    }

    &--row {
      margin-bottom: 0.5rem;
      align-items: stretch;
      flex: 2 0px;
      flex-flow: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }

  &__delete {
    height: 0;

    &-btn {
      padding-top: 0;

      &:active &-icon {
        font-weight: 900;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .person-card {
    &__contact-icon, &__contact-txt-a {
      display: none;
    }

    &__contact-txt-span {
      display: initial;
    }

    &__full-name {
      line-height: 1.25rem;
      font-size: 1rem;
      color: $tundora;
    }

  }
}

</style>
