<template>
  <messages-history
    :instant-messages='messages'
    :loading='loading'
    :error='error'
    :event-hub='eventHub'
    :current-user-id='currentUserId'
    :participants='participants'
    :can-chat="canChat"
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import MessagesHistory from 'messaging/components/MessagesHistory';

export default {
  name: 'messages-container',
  props: {
    eventHub: {
      type: Object,
    },
    canChat: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState('users', ['currentUserId']),
    ...mapState('instantMessages', {
      loading: 'loading',
      error: 'error',
    }),
    ...mapGetters('instantMessages', {
      messages: 'messagesByCreatedAt',
      participants: 'participantsByRole',
    }),
  },
  components: {
    apps: {
      'instant-messaging': {
        MessagesHistory,
      },
    },
  },
};
</script>
