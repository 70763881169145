<template>
  <div
    id='instant-messaging-ui'
    class='instant-messaging-ui'
    @click='createViewEvent'
    @focus='createViewEvent'
  >
    <back-nav-container v-if="hasBackNav" />
    <success-message />
    <chat-participants-container
      :has-chat-area="hasChatArea"
      />
    <div class='instant-messaging-ui__messages' id='instant-messaging-ui__messages' v-if="hasChatArea">
      <instant-messages-container :event-hub='eventHub' :can-chat="canChat" />
      <send-message-container
        :event-hub="eventHub"
        :show-status="hasStatusView"
        :current-participant="currentParticipant"
        :can-chat="canChat"
      />
    </div>
    <disclaimer v-if="hasChatArea && currentParticipant && canChat && !hasStatusView" />
  </div>
</template>

<script>
/* global RoostifyVariables */

import Vue from 'vue';
import SuccessMessage from 'shared/components/SuccessMessage';
import ChatParticipantsContainer from 'messaging/containers/ChatParticipantsContainer';
import InstantMessagesContainer from 'messaging/containers/InstantMessagesContainer';
import Disclaimer from 'messaging/components/Disclaimer';
import SendMessageContainer from 'messaging/containers/SendMessageContainer';
import instantMessageEventsApi from 'shared/api/instantMessageEvents';
import BackNavContainer from 'messaging/containers/BackNavContainer';
import { Roles, StatusViewRoles } from 'shared/constants/rolesConstants';
import { mapState, mapActions, mapGetters } from 'vuex';

const instantMessagingEvents = new Vue();

export default {
  name: 'active-conversation',
  data() {
    return {
      eventHub: instantMessagingEvents,
    };
  },
  components: {
    apps: {
      'instant-messaging': {
        InstantMessagesContainer,
        ChatParticipantsContainer,
        Disclaimer,
        SendMessageContainer,
        BackNavContainer,
      },
    },
    shared: {
      SuccessMessage,
    },
  },
  computed: {
    ...mapState('instantMessages', {
      loading: 'loading',
      error: 'error'
    }),
    ...mapGetters('instantMessages', {
      participants: 'participantsByRole',
    }),
    hasChatArea() {
      return Boolean(this.activeLoanApp.instant_messaging_visible && this.activeLoanApp.lender);
    },
    hasBackNav() {
      return RoostifyVariables.messageable_loan_applications;
    },
    hasStatusView() {
      const retVal = this.currentParticipant &&
        StatusViewRoles.includes(this.currentParticipant.role.global);
      return retVal;
    },
    canChat() {
      const userIsOnLoanAppTeam = !!(this.currentParticipant);
      const canInitiateChat = this.currentParticipant &&
        Roles.BORROWER === this.currentParticipant.role.global;
      const hasMessages = this.messages && this.messages.length > 0;
      return (canInitiateChat || hasMessages) && userIsOnLoanAppTeam;
    },
  },
  methods: {
    ...mapActions('instantMessages', {
      loadMessagesAndParticipants: 'loadMessagesAndParticipants',
    }),
    createViewEvent() {
      instantMessageEventsApi.createView(this.activeLoanApp.instantMessageEventsBaseUrl);
      this.activeLoanApp.unread_count = 0
    },
  },
  props: {
    activeLoanApp: {
      required: true,
      type: Object,
    },
    currentParticipant: {
      type: Object,
    },
    messages: {
      type: Array,
    },
  },
  beforeMount() {
    this.loadMessagesAndParticipants();
  },
};
</script>

<style lang='scss'>
@import "../assets/stylesheets/base";

.instant-messaging-ui {
  height: inherit;
  display: flex;
  flex-direction: column;

  & > * {
    padding: 1rem;
  }
}

.instant-messaging-ui__messages {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  order: 2;
  overflow: hidden;
  height: inherit;
  width: 100%;
  padding: 0;

  & > * {
    padding: 1rem;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .instant-messaging-ui__messages {
    overflow: visible;
  }
}

</style>
