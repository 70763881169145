import Vue from 'vue';
import Vuex from 'vuex';
import flashMessages from 'shared/src/store/flashMessages';
import instantMessages from './instantMessages';
import users from './users';
import pubnub from '../plugins/pubnubInstantMessagingPlugin';

Vue.use(Vuex);

export default function configureStore() {
  return new Vuex.Store({
    state: {iconMap: RoostifyVariables.icon_map,},
    modules: {
      instantMessages,
      users,
    },
    plugins: [flashMessages, pubnub],
  });
}
