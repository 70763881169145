<template>
    <a class="back-nav-container js-toggle-conversations-list"
      href="javascript:void(0)"
      tabindex="0"
      :aria-label="`close conversation with : ${activeLoanApp.name}`"
      @click="hideConversation"
    >
      <span class="back-nav-container__leftarrow"><i v-html="backNavIcon"></i></span>
      <span>{{activeLoanApp.name}}</span>
    </a>
</template>

<script>
export default {
  name: 'back-nav',
  props: {
    activeLoanApp: {
      type: Object,
      required: true,
    },
    backNavIcon: {
      type: String,
    },
    action: {
      type: Function,
      required: true,
    },
  },
  methods: {
    hideConversation() {
      this.action();
    },
  },
};
</script>

<style lang="scss">
  @import "../assets/stylesheets/base";
  .back-nav-container {
    cursor: pointer;

    &__leftarrow{
      color: $light-navy;
      padding: 0.5rem;
    }
  }
</style>
