<template>
  <div class="inline-person-card brand brand--small py-2">
    <span class="inline-person-card__name">
      {{ user.full_name || 'Invited'}},
    </span>
    <span class="inline-person-card__role">
      {{ person.role.team }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'inline-person-card',
    props: {
      person: {
        required: true,
        type: Object,
      },
    },
    computed: {
      user() {
        return this.person.user;
      },
    },
  };
</script>

<style lang="scss">
  @import "../assets/stylesheets/base";

  .inline-person-card {
    line-height: 1.43rem;
    border-bottom: 1px solid $gainsboro;

    &:last-child {
      border-bottom: none;
    }

    &__name {
      font-weight: 600;
    }
  }

</style>
