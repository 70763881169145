/* global RoostifyVariables */
import PubNub from 'pubnub';

let msgPubnub = {};

function newPubnubInstantMessage(store) {
  return (message) => {
    const messageEvents = [
      'instant_message:new',
      'instant_message_conversation:new',
      'refresh_participants_list'
    ];

    const activeLoanAppToken = store.state.instantMessages.activeLoanApp.token;
    if (activeLoanAppToken !== message.subscribedChannel) {
      store.dispatch('instantMessages/incrementUnreadCount', message.channel);
    } else if (messageEvents.includes(message.message.event)) {
      store.dispatch('instantMessages/loadMessagesAndParticipants');
    }
    if(activeLoanAppToken === message.subscribedChannel && 'instant_message:new' === message.message.event
      && RoostifyVariables.is_borrower && !message.message.sender_borrower){
      store.dispatch('instantMessages/incrementUnreadCountBorrower');
    }
  };
}

const addSubscriptions = (store) => {
  const instantMessagesState = store.state.instantMessages;
  const activeLoanApp = instantMessagesState.activeLoanApp;
  const loanApps = instantMessagesState.loanApps;
  const subscriptionLoanApps = !loanApps ? [activeLoanApp] : loanApps;

  if (subscriptionLoanApps && subscriptionLoanApps.length) {
    //create pubnub instance
    msgPubnub = new PubNub({
      subscribeKey: subscriptionLoanApps[0].pubnub_sub_key || RoostifyVariables.pubnub_sub_key,
      ssl: true,
    });

    // add listeners
    msgPubnub.addListener({
      message: newPubnubInstantMessage(store),
    });

    // add subscriptions
    subscriptionLoanApps.map(loanApp => {
      msgPubnub.subscribe({
        channels: loanApp.instantMessagesPubnubChannels,
      });
    });
  }

  return store;
};

export default function pubnubInstantMessagingPlugin(store) {
  addSubscriptions(store);
}
