<template>
  <article class='chat-participants pb-1'
    :class="(showParticipantsList || !hasChatArea) ? 'full-height' : 'initial-height'"
    >
    <a class="chat-participants__toggle-list mb-2"
      id='chat-participants__toggle'
      v-if="hasChatArea"
      data-toggle="collapse"
      role="button"
      aria-expanded="false"
      aria-controls="chat-participants__main-id"
      @keyup.space="toggleParticipantsList"
      @keyup.enter="toggleParticipantsList"
      @click="toggleParticipantsList"
      >
        <span class="chat-participants__toggle-list-txt mr-2">Loan team</span>
        <span class="chat-participants__toggle-list-arrow">
          <i v-if="showParticipantsList" v-html="angleDownIcon"></i>
          <i v-else v-html="angleRightIcon"></i>
        </span>
    </a>
    <section class="chat-participants__main" id="chat-participants__main-id"
      :class="[hasChatArea ? 'collapse' : '', showParticipantsList ? 'show' : '']">
      <div
        :role="(visibleParticipants.length > 1) ? 'list' : false"
        :aria-label="(visibleParticipants.length > 1) ? 'loan team members' : 'loan team member'"
      >
        <!-- Caters case when borrower has no Loan Officer assigned -->
        <person-card
          v-if="!activeLoanApp.lender"
          :role="(visibleParticipants.length > 1) ? 'listitem' : false"
          :person="loanAppAccount"
          :enable-license-number="activeLoanApp.enable_license_number"
          :show-contact-details="showContactDetails"
          :enable-lo-photo="enableLoPhoto"
        >
        </person-card>

        <person-card
          :role="(visibleParticipants.length > 1) ? 'listitem' : false"
          :key="person.user.id"
          :global-or-account-admin="globalOrAccountAdmin"
          :global-or-account-admin-or-lender="globalOrAccountAdminOrLender"
          :person="person"
          :enable-license-number="activeLoanApp.enable_license_number"
          :show-contact-details="showContactDetails"
          :enable-lo-photo="enableLoPhoto"
          :delete-button-icon='deleteButtonIcon'
          v-for="person in visibleParticipants"
        >
        </person-card>

        <inline-person-card
          :role="(visibleParticipants.length > 1) ? 'listitem' : false"
          :key="person.user.id"
          :person="person"
          v-for="person in collapsedParticipants"
        >
        </inline-person-card>
      </div>
      <div class="chat-participants__footer" v-if="canInvite || showToggleButton">

        <!-- For xsm and sm screens only -->
        <button v-if="canInvite"
          class="btn btn--link chat-participants__add-with-txt"
          :data-target="`#js--invite_modal_${activeLoanApp.token}`"
          data-toggle="modal"
          aria-label='Add Collaborator, open invite collaborator modal'
          aria-haspopup="true"
          >
          <i class="icon--utility far fa-user-plus chat-participants__add-with-txt-icon"></i>
          <i class="icon--utility fas fa-user-plus chat-participants__add-with-txt-icon--active"></i>
          <span class="chat-participants__add-with-txt-span pl-1">Add Collaborator</span>
        </button>

        <!-- For md, lg and xlg screens only -->
        <span data-toggle='tooltip' title='Add Collaborator'>
          <button v-if="canInvite"
            class="btn btn--circle btn--link chat-participants__add"
            :data-target="`#js--invite_modal_${activeLoanApp.token}`"
            data-toggle="modal"
            aria-label='Add Collaborator, open invite collaborator modal'
            aria-haspopup="true"
            >
            <i class="icon--utility far fa-user-plus chat-participants__add-icon"></i>
            <i class="icon--utility fas fa-user-plus chat-participants__add-icon--hover"></i>
          </button>
        </span>

        <button
          class="btn--disguised chat-participants__toggle-details-btn pl-0"
          role="button"
          :aria-expanded="showAllParticipants ? 'true': 'false'"
          @click="toggleParticipantsDetails"
          >
          <span class="chat-participants__toggle-details-txt mr-1">{{ toggleButtonText }}</span>
          <span class="chat-participants__toggle-details-arrow">
            <i v-html="toggleButtonIcon"></i>
          </span>
        </button>

      </div>
    </section>
  </article>
</template>

<script>
/* eslint space-in-parens: ["error", "never"] */
/* eslint-disable */
/* global RoostifyVariables */
import PersonCard from 'shared/components/PersonCard';
import InlinePersonCard from 'shared/components/InlinePersonCard';

export default {
  name: 'chat-participants',
  data() {
    return {
      showAllParticipants: false,
      showParticipantsList: false
    };
  },
  computed: {
    toggleButtonText() {
      return this.showAllParticipants === false ? 'See more' : 'See less';
    },
    toggleButtonIcon() {
      return this.showAllParticipants === true ? this.angleUpIcon : this.angleDownIcon;
    },
    visibleParticipants() {
      return (this.showAllParticipants || !this.hasChatArea) ? this.participants : this.participants.slice(0, 1);
    },
    collapsedParticipants() {
      return (this.showAllParticipants || !this.hasChatArea) ? [] : this.participants.slice(1);
    },
    loanAppAccount() {
      const {name: full_name, license_number, phone, email, photo: icon_url = RoostifyVariables.account_default_photo_url, ...restProps} = this.activeLoanApp.account;
      return { user: { full_name, license_number, phone, email, icon_url}, role: {}, canNotDelete: true };
    },
    showToggleButton() {
      return this.hasChatArea && (this.participants.length > 1);
    },
    showContactDetails() {
      return this.showAllParticipants || !this.showToggleButton;
    },
    canInvite() {
      return RoostifyVariables.loan_app_allow_write && this.activeLoanApp.enable_collaborators;
    },
    enableLoPhoto() {
      return this.activeLoanApp.enable_vanity_apps;
    },
    globalOrAccountAdminOrLender() {
      return this.activeLoanApp.global_or_account_admin_or_lender;
    },
  },
  mounted() {
    if($.R.Tooltip){
      $.R.Tooltip.init();
    }
  },
  methods: {
    toggleParticipantsDetails() {
      this.showAllParticipants = !this.showAllParticipants;
    },
    toggleParticipantsList() {
      this.showParticipantsList = !this.showParticipantsList;
    },
  },
  props: {
    participants: {
      required: true,
      type: Array,
    },
    activeLoanApp: {
      required: true,
      type: Object,
    },
    hasChatArea: {
      type: Boolean,
    },
    globalOrAccountAdmin: {
      type: Boolean,
    },
    deleteButtonIcon: {
      type: String,
    },
    angleUpIcon: {
      type: String,
    },
    angleDownIcon: {
      type: String,
    },
    angleRightIcon: {
      type: String,
    },
  },
  components: {
    apps: {
      'instant-messaging': {
        PersonCard,
        InlinePersonCard
      },
    },
  },
};
</script>

<style lang='scss'>
@import "../assets/stylesheets/base";

.chat-participants {
  align-content: flex-start;
  align-items: flex-start;
  background-color: #fff;
  border-bottom: 0.125rem solid $gainsboro;
  display: inherit;
  flex: none;
  flex-direction: column;
  font-size: .7rem;
  line-height: 1rem;
  order: 1;
  width: 100%;
  z-index: 50;

  box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.2);

  &__footer {
    display: inherit;
    justify-content: space-between;
    margin-bottom: 0.25rem;
    border-top: 1px solid $gainsboro;
  }

  &__toggle-details-btn{
    display: initial;
  }

  &__toggle-details-txt {
    @include f-sm;
    line-height: 1.43rem;
    color: $light-navy;
    font-size: 0.875rem;
  }

  &__toggle-details-arrow {
    @include f-sm;
    font-size: 1rem;
    color: $light-navy;
    vertical-align: middle;
  }

  &__add-with-txt {
    height: 2.5rem;
    font-size: 1.25rem;
    color: $light-navy;
    border: none;

    &-span {
      @include h6();
      color: $light-navy;
      font-size: 1rem;
      line-height: 1.44rem;
    }

    &-icon--active {
      display: none;
    }

    &:active &-icon {
        display: none;

        &--active{
          display: inline;
        }
    }
  }

  &__add {
    display: none;

    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.125rem;
    color: $light-navy;
    border: none;

    &-icon--hover {
      display: none;
    }

    &:hover &-icon {
        display: none;

        &--hover{
          display: inline;
        }
    }
  }

  &__toggle-list{
    width: 90%;

    &-txt {
      @include h2();
      font-size: 0.875rem;
      line-height: 1.43rem;
    }

    &-arrow{
      font-size: 1rem;
      color: $tundora;
    }
  }

}

.chat-participants__main {
  @include make-container();
  overflow-y: auto;
  overflow-x: hidden;

  background-color: white;
  height: inherit;
  width: inherit;
  transition: all 2s ease-out;
  &.v-enter,
  &.v-leave {
    bottom: 200px;
    position: relative;
    height: 0;
    opacity: 0;
  }
}

.full-height{
  height: 100%;
}

.initial-height {
  height: initial;
}

@include media-breakpoint-up(md) {
  .chat-participants {
    height: initial !important;

    &__main {
      display: initial !important;
    }

    &__footer {
      flex-direction: row-reverse;
    }

    &__toggle-details-btn
    {
      display: initial;
    }

    &__add {
      display: inline-flex;
    }

    &__toggle-list, &__add-with-txt
    {
      display: none;
    }
  }
}

</style>
