/* eslint-disable no-param-reassign */
export default function createFlashMessagePlugin(store) {
  store.registerModule('flashMessages', {
    namespaced: true,
    state: {
      error: null,
      success: null,
    },
    mutations: {
      setError(state, { error }) {
        state.error = error;
      },
      clearError(state) {
        state.error = '';
      },
      setSuccess(state, { success }) {
        state.success = success;
      },
      clearSuccess(state) {
        state.success = '';
      },
    },
    actions: {
      error({ commit }, error) {
        commit('setError', { error });
        setTimeout(() => { commit('clearError'); }, 5000);
      },
      success({ commit }, success) {
        commit('setSuccess', { success });
        setTimeout(() => { commit('clearSuccess'); }, 5000);
      },
    },
  });
}
