<template>
  <div>{{ message }}</div>
</template>

<script>
export default {
  name: 'error-overlay',
  props: {
    message: {
      type: String,
      default: 'Something went wrong.',
    },
  },
};
</script>
