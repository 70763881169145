<template>
  <notice-pane v-if="showNotice" />
</template>

<script>
import { mapState } from 'vuex';
import NoticePane from 'messaging/components/NoticePane';

export default {
  name: 'notice-pane-container',
  computed: {
    ...mapState('users', ['currentUserId']),
    ...mapState('instantMessages', {
      showActiveConversation: 'showActiveConversation',
      loanApps: 'loanApps',
      activeLoanApp: 'activeLoanApp',
    }),
    showNotice() {
      return !this.showActiveConversation && (this.loanApps && this.loanApps.length === 0)
        && (this.activeLoanApp.current_user_enable_instant_messaging !== false);
    },
  },
  components: {
    apps: {
      'instant-messaging': {
        NoticePane,
      },
    },
  },
};
</script>

