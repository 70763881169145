<template>
  <div class='conversations-list-container'>
    <h2 class="conversations-list__title">Messages</h2>
    <div class="list-group list-group-flush conversations-list"
      tabindex="0"
      role="list"
      aria-label="active conversations"
      >
      <li class="list-group-item list-group-item-action conversations-list-item"
        v-for="loanApp in loanApps"
        :key="loanApp.token"
        role="listitem"
        @click="openConversation(loanApp)"
        >
        <button class="btn btn--anchor conversations-list-item__anchor"
          :class="`js-toggle-conversations-list__item--${loanApp.token}`"
          >
          <span class="conversations-list-item__name">{{loanApp.name}}</span>
          <span v-if="loanApp.unread_count > 0"
            class="c-badge conversations-list-item__unreadCount"
            :aria-label="`: ${loanApp.unread_count} unread messages`"
            aria-live="polite"
          >{{loanApp.unread_count}}</span>
        </button>
      </li>
      <center>
        <div class='loading-conversations-list'></div>
      </center>
    </div>
  </div>
</template>

<script>
export default {
  name: 'conversations-list',
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    removeDumplicateValue(myArray) {
      var newArray = [];
      $.each(myArray, function(key, value) {
        var exists = false;
        $.each(newArray, function(k, val2) {
          if(value.token == val2.token){ exists = true };
        });
        if(exists == false && value.id != "") { newArray.push(value); }
      });
      return newArray;
    },
    openConversation(loanApp) {
      this.action(loanApp);
    },
  },
  destroyed() {
    test.removeEventListener("scroll");
  },
  mounted() {
    const that = this;
    let page = 2; // page first loads on the window load
    var test = document.getElementById("panel-secondary");
    test.scrollTo(0, 0);
    var timeout;
    let flag = true;
    test.addEventListener("scroll", function () {
      clearTimeout(timeout);
      if (($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) && flag) {
        flag = false;
        timeout = setTimeout(function() {
          $.ajax({
            url: '/fetch_messageable_loan_applications',
            type: 'POST',
            data: { page },
            beforeSend: function() {
              $(".loading-conversations-list").empty();
              $(".loading-conversations-list").append("<img src='" + RoostifyVariables.spinner_gif +"' alt='Loading...' />");
            },
            success: function(response) {
              $(".loading-conversations-list").empty();
              if (response.data.length > 0) {
                var loanAppsSubset = that.loanApps.concat(response.data);
                that.loanApps = that.removeDumplicateValue(loanAppsSubset);
                page += 1
                flag = true;
              }
            },
            failure: function() {
              $(".loading-conversations-list").empty();
            }
          });
        }, 500);
      }
    });
  },
  props: {
    loanApps: {
      required: true,
      type: Array,
    },
    action: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang='scss'>
@import "../assets/stylesheets/base";
.conversations-list {
  &__title {
    padding: 1rem;
  }

  &-item {
    cursor: pointer;
    border-bottom: 1px solid $gainsboro !important;

    &:first-child {
      border-top: 0;
    }

    &__anchor {
      display: block;
    }

    &__name {
      padding: 0 0.5rem;
    }

    &__unreadCount {
      border-radius: 0.875rem;
      font-weight: bold;
      height: 1.25rem;
      min-width: 1.25rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

}
</style>
