<template>
  <send-message
    v-if="canChat"
    :action='createInstantMessage'
    :on-typing='createTypingEvent'
    :event-hub='eventHub'
    :active-loan-app='activeLoanApp'
    :show-status="showStatus"
    :borrower-participant='borrowerParticipant'
    :current-participant="currentParticipant"
    :send-button-icon='sendButtonIcon'
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { debounce, find } from 'lodash';
import SendMessage from 'messaging/components/SendMessage';
import instantMessageEventsApi from 'shared/api/instantMessageEvents';
import { Roles } from 'shared/constants/rolesConstants';

export default {
  name: 'messages-container',
  props: {
    eventHub: {
      type: Object,
    },
    showStatus: {
      type: Boolean,
    },
    currentParticipant: {
      type: Object,
    },
    canChat: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState('instantMessages', {
      activeLoanApp: 'activeLoanApp',
      participants: 'participants',
    }),
    ...mapState({
      iconMap: 'iconMap',
    }),
    sendButtonIcon() {
      return this.iconMap.msg_send_button_icon ? this.iconMap.msg_send_button_icon : '';
    },
    borrowerParticipant() {
      return find(this.participants, participant => participant.role.global === Roles.BORROWER);
    },
  },
  methods: {
    ...mapActions('instantMessages', {
      createInstantMessage: 'create',
    }),
    createTypingEvent: debounce(
      (activeLoanApp) => {
        const url = activeLoanApp.instantMessageEventsBaseUrl;
        instantMessageEventsApi.createTyping(url);
      }, 3000),
  },
  components: {
    apps: {
      'instant-messaging': {
        SendMessage,
      },
    },
  },
};
</script>
