/* eslint-disable no-param-reassign */

export default {
  namespaced: true,
  state: {
    currentUserId: null,
    global_or_account_admin: false,
  },
  mutations: {
    setCurrentUser(state, { user }) {
      state.currentUserId = user.id;
      state.globalOrAccountAdmin = user.global_or_account_admin;
    },
  },
};
