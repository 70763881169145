<template>
  <div class='disclaimer'>
    <span v-bind:class="disclaimerClass">
      {{ disclaimerText }}
    </span>

    <a
      class="disclaimer__toggle-link"
      role="button"
      href="javascript:void(0);"
      v-bind:aria-expanded="ariaExpanded"
      >
    </a>

  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'disclaimer',
  data() {
    return {
      fullTextDisplayed: true,
    };
  },
  computed: {
    disclaimerClass() {
      return this.fullTextDisplayed; 
    },
    disclaimerText() {
      if(this.fullTextDisplayed) {
        return 'Privacy notice: Do not post personal information within messages, such as account numbers, social security numbers, or other sensitive or personal information. The information you post may be viewable and/or transmitted to parties connected to the loan application.'
      }
    },
    ariaExpanded() {
      return this.fullTextDisplayed;
    },
    ...mapState({
      iconMap: 'iconMap',
    }),
  }
};
</script>

<style lang='scss'>
@import "../assets/stylesheets/base";

.disclaimer {
  padding-top: 0;
  flex: none;
  order: 2;
  @include f-xs;

  &__full-text {
    margin-bottom: .35rem;
  }

  &__toggle-link {
    @include f-xs;
    color: $light-navy !important;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    margin-top: 1.1rem;
  }
}
</style>
