<template>
  <div v-if="message" aria-live="assertive" class="alert c-alert c-alert--floating c-alert--success fade js-changes-saved-notification show vue-success" role="alert">
    <div class="d-flex">
      <div class="c-alert__icon--big c-alert__icon--success">
        <div class=" fa-check-circle fal"></div>
      </div>
      <div class="c-alert__text c-alert__text--floating js-notification-message">{{message}}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'success-message',
  computed: {
    ...mapState('flashMessages', {
      message: 'success',
    }),
  },
};
</script>

<style lang="scss">
  @import "../assets/stylesheets/base";
  .vue-success {
    position: absolute;
    top: 0.5rem;
    width: 100%;
    padding: 0.7rem;
    background: lighten($moss-green, 35%);
    border: 1px solid lighten($moss-green, 10%);
    box-shadow: 0 0 6px lighten($moss-green, 20%);
    z-index: 100;
    }
</style>

