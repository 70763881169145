/* global RoostifyVariables */
import Vue from "vue";

import InstantMessaging from "../messaging/src/InstantMessagingApp";
import configureStore from "../messaging/src/store";
import VueTimeago from "vue-timeago";
import RegistryPlugin from "shared/src/util/registry";

Vue.use(RegistryPlugin, {});
Vue.use(VueTimeago, {
  name: "timeago",
  locale: "en-US",
  locales: {
    "en-US": [
      "new",
      ["sent %s second ago", "sent %s seconds ago"],
      ["sent %s minute ago", "sent %s minutes ago"],
      ["sent %s hour ago", "sent %s hours ago"],
      ["sent %s day ago", "sent %s days ago"],
      ["sent %s week ago", "sent %s weeks ago"],
      ["sent %s month ago", "sent %s months ago"],
      ["sent %s year ago", "sent %s years ago"]
    ]
  }
});

document.addEventListener("DOMContentLoaded", () => {
  if (!document.getElementById("vue_instant_messaging")) return null;

  const store = configureStore();
  store.commit("users/setCurrentUser", {
    user: {
      id: RoostifyVariables.user_id,
      global_or_account_admin: RoostifyVariables.global_or_account_admin
    }
  });

  return new Vue({
    el: "#vue_instant_messaging",
    store,
    render: h => h(InstantMessaging)
  });
});
