import { assign, omit, reduce } from 'lodash';

function omitRoostifyNamespaces(registry) {
  return omit(registry, 'shared', 'apps');
}

function componentRegistryMergeStrategy(overrideRegistry) {
  return function (vueRegistry = {}, localRegistry = {}) {
    const shared = assign({}, (localRegistry.shared || {}), overrideRegistry.shared || {});
    const relevantAppKeys = localRegistry.apps ? Object.keys(localRegistry.apps) : [];
    const apps = reduce(relevantAppKeys, (result, key) => {
      const overrides = overrideRegistry.apps ? overrideRegistry.apps[key] : {};
      const appRegistry = assign({}, localRegistry.apps[key], overrides);
      return assign({}, result, appRegistry);
    }, {});

    return assign(
      {},
      omitRoostifyNamespaces(localRegistry),
      omitRoostifyNamespaces(vueRegistry),
      omitRoostifyNamespaces(overrideRegistry),
      shared,
      apps,
    );
  };
}

export default {
  install(V, registry) {
    /* eslint-disable no-param-reassign */
    V.config.optionMergeStrategies.components = componentRegistryMergeStrategy(registry);
    /* eslint-enable no-param-reassign */
    // triggers merge strategies... idk either
    V.mixin({});
  },
};
