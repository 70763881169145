/* eslint-disable import/prefer-default-export */
import 'isomorphic-fetch';

function fetchAndUnwrapWithCookies(uri, additionalOptions = {}) {
  const options = { credentials: 'include', ...additionalOptions };
  return fetch(uri, options).then(response => response.json());
}

function createView(url) {
  const formData = new FormData();
  formData.append('instant_message_event[type]', 'view');
  resetUnreadCountBorrower();

  return fetchAndUnwrapWithCookies(url, {
    method: 'POST',
    body: formData,
  });
}

function createTyping(url) {
  const formData = new FormData();
  formData.append('instant_message_event[type]', 'typing');
  resetUnreadCountBorrower();

  return fetchAndUnwrapWithCookies(url, {
    method: 'POST',
    body: formData,
  });
}

function resetUnreadCountBorrower(){
  if (RoostifyVariables.is_borrower){
    $('.js-unread-count').addClass('hide');
    $('.js-unread-count').text(0);
  }
}

export default {
  createView,
  createTyping,
};
