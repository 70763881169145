<template>
  <back-nav
    :active-loan-app="activeLoanApp"
    :action="hideActiveConversationAction"
    :back-nav-icon='backNavIcon'
  />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BackNav from 'messaging/components/BackNav';

export default {
  name: 'back-nav-container',
  computed: {
    ...mapState({ iconMap: 'iconMap',}),
    ...mapState('users', ['currentUserId']),
    ...mapState('instantMessages', {
      activeLoanApp: 'activeLoanApp',
    }),
    backNavIcon(){
      return this.iconMap.msg_arrow_left_icon ? this.iconMap.msg_arrow_left_icon : '';
    }
  },
  methods: {
    ...mapActions('instantMessages', {
      hideActiveConversationAction: 'hideActiveConversation',
    }),
  },
  components: {
    apps: {
      'instant-messaging': {
        BackNav,
      },
    },
  },
};

</script>
