/* eslint-disable import/prefer-default-export */
import 'isomorphic-fetch';

function fetchAndUnwrapWithCookies(uri, additionalOptions = {}) {
  const options = { credentials: 'include', ...additionalOptions };
  return fetch(uri, options).then(response => response.json());
}

export function fetchMessages(url) {
  return fetchAndUnwrapWithCookies(url);
}

export function create(data, url) {
  const formData = new FormData();
  formData.append('instant_message[content]', data.content);

  return fetchAndUnwrapWithCookies(url, {
    method: 'POST',
    body: formData,
  });
}

export function fetchParticipants(url) {
  return fetchAndUnwrapWithCookies(url);
}
