<template>
  <div class="notice-pane">
    <h2 class="notice-pane__title">Messaging</h2>
    <div class="notice-pane__text">
        <p class="brand">Messaging is on and incoming messages will appear here.</p>
        <p class="notice-pane__dotted-line" />
        <p class="brand brand--sm">The system will alert you when a new message comes in from any of your borrowers.</p>
        <p class="brand brand--sm">If you are not logged in, we’ll forward your incoming messages via email.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notice-pane',
};
</script>

<style lang="scss">
  @import "../assets/stylesheets/base";
  .notice-pane {
    padding: 1rem;

    &__text {
      padding-top: 2rem;
    }

    &__dotted-line {
      border-bottom: 0.125rem dotted $light-grey;
    }
  }
</style>

