<template>
  <chat-participants
    :activeLoanApp="activeLoanApp"
    :participants="participants"
    :has-chat-area="hasChatArea"
    :global-or-account-admin="globalOrAccountAdmin"
    :delete-button-icon='deleteButtonIcon'
    :angle-up-icon='angleUpIcon'
    :angle-down-icon='angleDownIcon'
    :angle-right-icon='angleRightIcon'
  >
  </chat-participants>
</template>

<script>
import { mapState } from 'vuex';
import ChatParticipants from 'messaging/components/ChatParticipants';
import { Roles } from 'shared/constants/rolesConstants';

export default {
  name: 'chat-partipants-container',
  computed: {
    ...mapState('instantMessages', {
      loading: 'loading',
      error: 'error',
      activeLoanApp: 'activeLoanApp',
    }),
    ...mapState('users', ['currentUserId', 'globalOrAccountAdmin']),
    ...mapState({
      iconMap: 'iconMap',
    }),
    deleteButtonIcon() {
      return this.iconMap.msg_delete_button_icon ? this.iconMap.msg_delete_button_icon : '';
    },
    angleUpIcon() {
      return this.iconMap.msg_angle_up_icon ? this.iconMap.msg_angle_up_icon : '';
    },
    angleDownIcon() {
      return this.iconMap.msg_angle_down_icon ? this.iconMap.msg_angle_down_icon : '';
    },
    angleRightIcon() {
      return this.iconMap.msg_angle_right_icon ? this.iconMap.msg_angle_right_icon : '';
    },
    participants() {
      const orderedParticipants = this.$store.getters['instantMessages/participantsByRole'];
      this.addPropsOnParticipants(orderedParticipants);
      return orderedParticipants.filter(x => this.isCollaborator(x));
    },
  },
  props: {
    hasChatArea: {
      type: Boolean,
    },
  },
  methods: {
    addPropsOnParticipants(participants) {
      participants.map(p => Object.assign(p, {
        isLender: p.role.global === Roles.LENDER,
      }));
    },
    isCollaborator(participant) {
      const role = participant.role.global;
      return (role !== Roles.BORROWER);
    },
  },
  components: {
    apps: {
      'instant-messaging': {
        ChatParticipants,
      },
    },
  },
};
</script>
