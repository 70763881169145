export const Roles = {
  BORROWER: 'borrower',
  UNDERWRITER: 'underwriter',
  ASSISTANT: 'assistant',
  PROCESSOR: 'processor',
  LENDER: 'lender',
  ADMIN: 'admin',
  DEVELOPER: 'developer',
  BORROWER_AGENT: 'borrower_agent',
  ESCROW_OFFICER: 'escrow_officer',
  TITTLE_ATTORNEY: 'title_attorney',
  OBSERVER: 'observer',
};

export const StatusViewRoles = [
  Roles.UNDERWRITER,
  Roles.ASSISTANT,
  Roles.PROCESSOR,
  Roles.LENDER,
  Roles.ADMIN,
  Roles.DEVELOPER,
];

export default {
  Roles,
  StatusViewRoles,
};
