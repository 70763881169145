<template>
  <div class='disclaimer-text participant-status'>
    <span class='participant-status__indicator' v-bind:class='{ active: user.active }'><i class="fas fa-circle"></i></span>
    <span class='participant-status__message'>{{statusMessage}}</span>
  </div>
</template>

<script>
export default {
  name: 'participant-status',
  props: {
    participant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeMessage: 'is available',
      inactiveMessage: 'is unavailable',
    };
  },
  computed: {
    statusMessage() {
      const name = this.user.full_name;
      const note = this.user.active ? this.activeMessage : this.inactiveMessage;
      return `${name} ${note}`;
    },
    user() {
      return this.participant.user;
    },
  },
  methods: {
  },
};
</script>

<style lang='scss'>
@import "../assets/stylesheets/base";

.participant-status {
  padding: 0.5rem 0;

  &__indicator {
    margin-right: 0.25rem;
    color: $tango;

    &.active {
      color: $moss-green;
    }
  }
}

</style>

